<template>
  <router-view/>
</template>

<style>
#app {

}
  body {
    /* background: url('./'); */
    /* background-repeat: no-repeat; */
    /* background-size: 300px 100px; */
  }
@media (prefers-color-scheme: dark) {
  body {
    background: #000;
  }
}
@media (prefers-color-scheme: light) {
  body {
    background: #fff;
  }
}

</style>
