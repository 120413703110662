<template>
<div class="md:container md:mx-auto canvasFill">
  <div>
    <TransitionRoot :show="true">
      <!-- Background overlay -->
      <TransitionChild enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
        <img v-if="!portrait" class="mainLogo" src="/img/brand/logo_holder.png" />
        <img v-if="portrait" class="mainLogo" src="/img/brand/logo_holder_portrait.png" />

      </TransitionChild>
    </TransitionRoot>
  </div>
</div>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild
} from '@headlessui/vue'

export default {
  name: 'HelloWorld',
  components: {
    TransitionRoot,
    TransitionChild
  },
  data() {
    return {
      portrait: false
    }
  },
  mounted() {
    console.log("Well this is awkward, but here we both are...")
    if (window.matchMedia("(orientation: portrait)").matches) {
      this.portrait = true;
      // console.log("Portrait")
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      // console.log("Landscape")
      this.portrait = false;
    }
  }
}
</script>
<style>
.mainLogo {
  max-width: 444px;
  background-image: url('../../public/img/brand/spar.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding-top: 4px;
}
@media (prefers-color-scheme: dark) and (orientation:landscape) {
  .mainLogo {
    background-image: url('../../public/img/brand/spar_dark.png');
  }
}

@media (prefers-color-scheme: light) and (orientation:landscape) {
  .mainLogo {
    background-image: url('../../public/img/brand/spar.png');
  }
}

@media (prefers-color-scheme: dark) and (orientation:portrait) {
  .mainLogo {
    background-image: url('../../public/img/brand/spar_portrait_dark.png') !important;

  }
}
@media (prefers-color-scheme: light) and (orientation:portrait) {
  .mainLogo {
    background-image: url('../../public/img/brand/spar_portrait.png') !important;

  }
}
</style>
